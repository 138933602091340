var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page list-ges" },
    [
      _c(
        "label",
        { staticClass: "categoryName" },
        [
          _c("i", {
            staticClass: "el-icon--home",
            on: {
              click: function($event) {
                return _vm.jumpPage({ name: "home" })
              }
            }
          }),
          _vm.cname
            ? [
                _c("i", { staticClass: "el-icon--right" }),
                _c(
                  "span",
                  {
                    staticClass: "category",
                    on: {
                      click: function($event) {
                        return _vm.backToCategory()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.cname))]
                )
              ]
            : _vm._e(),
          _c("i", { staticClass: "el-icon--right" }),
          _c("i", {
            class: _vm.type === 4 ? "el-icon--group" : "el-icon--company"
          })
        ],
        2
      ),
      _vm.items.length
        ? _c(
            "div",
            { staticClass: "items-list" },
            [
              _vm._l(_vm.items, function(item, key) {
                return [
                  _c("gigItem", {
                    key: key,
                    attrs: {
                      item: item,
                      type: _vm.type.toString(),
                      "light-height": _vm.searchKey ? true : false,
                      keyword: _vm.searchKey
                    }
                  })
                ]
              })
            ],
            2
          )
        : _c("noData"),
      _c("el-pagination", {
        attrs: {
          background: "",
          layout: "prev, pager, next",
          "page-size": _vm.pageSize,
          "hide-on-single-page": true,
          total: _vm.total,
          "current-page": _vm.pageNum
        },
        on: { "current-change": _vm.pageChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }