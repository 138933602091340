<template>
  <div class="page list-ges">
    <label class="categoryName">
      <i class="el-icon--home" @click="jumpPage({ name: 'home' })"></i>

      <template v-if="cname">
        <i class="el-icon--right"></i>
        <span class="category" @click="backToCategory()">{{ cname }}</span>
      </template>
      <i class="el-icon--right"></i>
      <i :class="type === 4 ? 'el-icon--group' : 'el-icon--company'"></i>
    </label>
    <div class="items-list" v-if="items.length">
      <template v-for="(item, key) in items">
        <gigItem
          :item="item"
          :key="key"
          :type="type.toString()"
          :light-height="searchKey ? true : false"
          :keyword="searchKey"
        />
      </template>
    </div>
    <noData v-else />
    <el-pagination
      background
      layout="prev, pager, next"
      :page-size="pageSize"
      :hide-on-single-page="true"
      :total="total"
      :current-page="pageNum"
      @current-change="pageChange"
    ></el-pagination>
  </div>
</template>
<script>
import gigItem from "@/components/business/web-site/gig_item";
import noData from "@/components/business/web-site/not-data";
export default {
  data() {
    return {
      cid: 0,
      cname: "",
      type: 4,
      params: {},
      pageNum: 1,
      pageSize: 50,
      total: 0,
      items: [],
      country: "",
      searchKey: ""
    };
  },
  components: {
    gigItem,
    noData
  },
  computed: {
    watchCategorysId() {
      return this.$store.state.webStore.categoryId;
    },
    watchLocation() {
      return this.$store.state.webStore.location;
    }
  },
  watch: {
    watchCategorysId: {
      handler(val) {
        let self = this;
        if (val) self.cname = self.findCurrentCate(val);
        self.params.categoryId = self.cid = val;
        self.params.pageNum = self.pageNum = 1;
        self.getlistData();
      },
      deep: true
    },
    watchLocation: {
      handler(val) {
        this.params.country = val[1];
        this.getlistData();
      },
      dreep: true
    }
  },
  created() {
    let type = this.$route.params.type;
    let category_id = this.$route.params.categoryId;

    this.cid = parseInt(category_id) || this.$store.state.webStore.categoryId;
    if (this.cid) this.cname = this.findCurrentCate(this.cid);

    if (type) {
      this.$store.dispatch("commitType", parseInt(type));
      this.type = parseInt(type) || this.$store.state.webStore.type;
    }
    if (category_id) {
      this.$store.dispatch("commitCateGoryId", parseInt(category_id));
    }
    if (this.$route.params.key) this.searchKey = this.$route.params.key;

    this.params = {
      categoryId: this.cid,
      pageNum: this.pageNum,
      pageSize: this.pageSize,
      country: this.country || this.$store.state.webStore.location[1],
      userId: this.$store.getters.userInfo.id || "",
      searchKey: this.searchKey || "",
      language: localStorage.getItem("langCode") || ""
    };
    this.getlistData();
  },
  methods: {
    backToCategory() {
      this.$store.dispatch("commitType", 0);
      this.jumpPage({ name: "list", params: { categoryId: this.cid } });
    },
    getlistData() {
      let page = arguments[0];
      if (page) {
        this.params.pageNum = page;
      }
      switch (this.type) {
        case 4:
          this.getGroups();
          break;
        case 5:
          this.getEnterPri();
      }
    },
    pageChange(page) {
      this.pageNum = page;
      this.getlistData(page);
    },
    async getGroups() {
      let result = await this.$http({
        url: "rest/list/v3/getGroupList",
        params: this.params,
        withCredentials: false
      });
      this.items = this.formartGroups(result.data);
      this.total = result.total;
    },
    async getEnterPri() {
      let result = await this.$http({
        url: "rest/list/v3/getEnterpriseList",
        params: this.params,
        withCredentials: false
      });
      this.total = result.total;
      this.items = this.formartEnters(result.data);
    },
    formartGroups(arr) {
      let newArr = [];
      arr.forEach(element => {
        let item = {
          avatar: element.group_header,
          name: element.group_name,
          id: element.group_id,
          desc: this.filterHtmlTags(element.group_info),
          owner: element.group_owner,
          tags:
            element.group_tag && element.group_tag.indexOf(",")
              ? element.group_tag.split(",")
              : element.group_tag !== ""
              ? [element.group_tag]
              : [],
          actions: {
            id: element.group_id,
            zanNum: element.like_count,
            replyCount: element.reviewer_count,
            type: this.type,
            likeStatus: element.like_id ? true : false,
            shareData: {
              title: element.group_name,
              thumb: element.group_header,
              desc: this.filterHtmlTags(element.group_info),
              url: "/detail/" + element.group_id + "/4"
            }
          }
        };
        newArr.push(item);
      });
      return newArr;
    },
    formartEnters(arr) {
      let newArr = [];
      arr.forEach(element => {
        let item = {
          avatar: element.enterprise_logo,
          name: element.enterprise_name,
          id: element.id,
          desc: this.filterHtmlTags(element.enterprise_info),
          owner: element.enterprise_owner,
          tags:
            element.enterprise_tag && element.enterprise_tag.indexOf(",")
              ? element.enterprise_tag.split(",")
              : element.enterprise_tag !== ""
              ? [element.enterprise_tag]
              : [],
          actions: {
            id: element.id,
            zanNum: element.like_count,
            replyCount: element.reviewer_count,
            type: this.type,
            likeStatus: element.like_id ? true : false,
            shareData: {
              title: element.enterprise_name,
              thumb: element.enterprise_logo,
              desc: this.filterHtmlTags(element.enterprise_info),
              url: "/detail/" + element.id + "/5"
            }
          }
        };
        newArr.push(item);
      });
      return newArr;
    }
  }
};
</script>
<style lang="stylus" scoped>
p {
  margin: 0;
  padding: 0;
}

.list-ges {
  .categoryName {
    display: flex;
    flex-flow: row;
    font-size: 18px;
    margin: 1.5rem 0 0.9rem 0;
    // justify-content: space-between;
    align-items: center;
    padding: 0 0.9rem 0 0;

    span {
      font-weight: bold;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    .el-icon--home, .el-icon-user-solid {
      font-size: 1.2rem;
      color: #33cc66;
      cursor: pointer;

      // margin-right: 0.25rem;
      &:hover {
        opacity: 0.8;
      }
    }

    .el-icon-user-solid {
      color: #333333;
    }

    .el-icon--right {
      color: #666666;
      margin: 0 0.25rem;
    }
  }

  .items-list {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
